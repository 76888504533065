import { useEffect, useRef, useState } from 'react';
import {useSelector} from 'react-redux';
import { URI } from "../../constants";
import {selectAllProductsFromCart} from '../../selectors';

export const useCDEK = () => {
  const [pvz, setPvz] = useState(null);
  const cdekRef = useRef();
  const cartProducts = useSelector(selectAllProductsFromCart);
  const deliveryType = useRef('');

  // eslint-disable-next-line no-undef
  const [CDEK, setCDEK] = useState(null);

  useEffect(() => {
    if (!window.CDEKWidget || CDEK) return;

    setCDEK(new window.CDEKWidget(
      {
        root: 'forpvz',
        defaultLocation: 'Санкт-Петербург',
        from: 'Санкт-Петербург',
        servicePath: `${URI}/api/sdek_service`,
        apiKey: '26329bb8-426b-4c13-95fe-8a72f2bb521b',
        hideFilters: {
          have_cashless: true,
          have_cash: true,
          is_dressing_room: true,
          type: true,
        },
        tariffs: {
          office: [138],
          door: [139],
          pickup: [366],
        },
        debug: true,
        onChoose: (type, tariff, address) => {
          setPvz({ price: tariff.delivery_sum, address, type });
          deliveryType.current = type;
        },
        onCalculate(tariff) {
          setPvz((prevState) => ({ ...prevState, price: prevState?.type && tariff[prevState.type].length > 0 ? tariff[prevState.type][0]?.delivery_sum : null }));
        },
      }));
  }, [CDEK, cartProducts]);

  useEffect(() => {
    if (!cartProducts || cartProducts?.length === 0 || !CDEK) return;

    CDEK.resetParcels();
    CDEK.addParcel(cartProducts.map((p) => ({ width: p?.width ? Math.ceil(p.width) : 1, height: p?.height ? Math.ceil(p?.height) : 1, length: p?.length ? Math.ceil(p.length) : 1, weight: p?.weight ?? 1 })));
  }, [CDEK, cartProducts]);

  return {
    pvz,
    cdekRef,
    setPvz
  };
}; 