// Libraries
import Cookies from 'js-cookie';
import {useDispatch, useSelector} from "react-redux";
import { Form } from 'react-final-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

// Components
import Page from '../../components/Page';
import { Basket, Contacts, HaveAcc, DeliveryType, PaymentType, Confirmations } from './components';

// Hooks
import {
  useDeleteProductFromCart,
  useCart,
  useCreateOrder,
  useGetUserInfo,
  useProducts,
  useGetCommonPriceCart,
} from '../../hooks';

// Helpers
import {
  validateOrdering,
  getShippingMethod,
  getProductsFromCart,
  convertPaymentMethodToNumber,
} from './helpers';
import { isEmptyObject, getUserFirstName, getUserLastName } from '../../helpers';
import { useCDEK } from './CDEKService';

// Actions
import { SET_PROMO } from '../../actions/setPromo';
import { setPromoError, setPromoIsUsed, setPromoValue } from '../../actions';

// Selectors
import { selectPromoIsUsed } from '../../selectors';

// Constants
import { BASKET_ITEM_TYPES, DELIVERY_TYPES, URI, USER_COOKIE } from '../../constants';

// Utils
import processArray from '../../utils/processArray';

// Styles
import styles from './style.module.scss';
import buttonStyles from '../../components/Button/style.module.scss';
import { useCities } from "./hooks";
import classnames from "classnames";
import { Loader } from "../../components/Loader";
import {FREE_DELIVERY_BORDER} from "./components/Basket";

const DEFAULT_DELIVERY_DATA = {
  courier_place: 'toDoor',
  shop: {
    id: 1,
    name: 'Мультимузейный магазин',
    address: {
      name: 'г.Санкт-Петербург, Дворцовая площадь, д. 6-8. Главный Штаб',
      link: '/about',
    },
    workingTime: [
      'Понедельник - выходной',
      'Вторник - 11.00 - 20.00',
      'Среда - 11.00 - 18.00',
      'Четверг - 11.00 - 18.00',
      'Пятница - 11.00 - 20.00',
      'Суббота - 11.00 - 20.00',
      'Воскресенье - 11.00 - 18.00'
    ],
    deliveryTime: '1-3 рабочих дня',
  },
};

const PAYMENTS_TYPES = {
  pauy: 'pauy',
  cash: 'cash',
  card: 'card',
};

export const DEFAULT_ORDER_DATA = {
  delivery: {
    type: DELIVERY_TYPES.courier,
    data: DEFAULT_DELIVERY_DATA,
  },
  paymentMethod: PAYMENTS_TYPES.pauy,
  basket: [
    {
      id: 1,
      imgSrc: 'https://www.hermitageshop.ru/image/data/2021/fashion/DSC05598.jpg',
      type: BASKET_ITEM_TYPES.ticket,
      category: 'Одежда и акессуары',
      name: 'Vulputate potenti ut',
      price: 1500,
      date: moment().format('DD.MM.YYYY'),
      count: 1,
    },
    {
      id: 2,
      imgSrc: 'https://www.hermitageshop.ru/image/data/2021/fashion/DSC05598.jpg',
      type: BASKET_ITEM_TYPES.ticket,
      category: 'Одежда и акессуары',
      name: 'Vulputate potenti ut',
      price: 510,
      date: moment().format('DD.MM.YYYY'),
      count: 12,
    },
    {
      id: 3,
      type: BASKET_ITEM_TYPES.delivery,
      name: 'Доставка до двери',
      price: 0,
      date: moment().format('DD.MM.YYYY'),
    },
  ],
  confirmations: {
    readPersonalData: true,
    subscribe: false,
    createAcc: false,
  },
  comment: '',
};

const additionalPrice = 0;

const Ordering = () => {
  const promoData = useSelector(store => store?.promoReducer?.data);
  const dispatch = useDispatch();
  const cities = useCities();
  const { pvz, cdekRef, setPvz } = useCDEK();
  const userCookie = Cookies.get(USER_COOKIE);
  const [orders, setOrders] = useState(DEFAULT_ORDER_DATA);
  const { name, email, phone, userInfoLoading } = useGetUserInfo(); // временно убрали данные юзера
  const { createOrderHandler } = useCreateOrder();
  const { products, loading: productsLoading } = useCart();
  const initialValues = {
    courier_place: 'toPoint',
    pickup_place: 1,
    payment_method: 'pauy',
    email,
    phone,
    name,
    city: null,
    promoCode: null,
  };
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { deleteProductFromCartHandler } = useDeleteProductFromCart();
  const promoIsUsed = useSelector(selectPromoIsUsed);
  const { cartPrice } = useGetCommonPriceCart(products, promoData);
  const [orderingError, setOrderingError] = useState('');

  useEffect(() => {
    if (!promoIsUsed) {
      dispatch(setPromoValue(''));
    }
  }, []);

  const getDeliveryInfo = (info) => {
    setDeliveryInfo(info);
  };

  const deliveryPrice = useMemo(() => {
    if (products.length === 0) {
      return null;
    }

    if (cartPrice > FREE_DELIVERY_BORDER) {
      return 0;
    }

    return `${Number(pvz?.price) + additionalPrice}`;
  }, [products.length, cartPrice, deliveryInfo, pvz]);

  const onSubmit = useCallback(async (values) => {
    let address = '';

    if (pvz?.type === 'door' && pvz?.address) {
      address = `${pvz.address.city}, ${pvz.address.formatted}, индекс ${pvz.address.postal_code}`;
    }

    if (pvz?.type === 'office' && pvz?.address) {
      address = `${pvz.address.city}, ${pvz.address.code}, ${pvz.address.address}, индекс ${pvz.address.postal_code}`;
    }


    const input = {
      products: getProductsFromCart(products),
      firstname: getUserFirstName(values.name),
      lastname: getUserLastName(values.name),
      email: values.email,
      phone: values.phone.replace(/[^+\d]/g, ''),
      shipping_method: getShippingMethod(pvz?.type),
      payment_method: convertPaymentMethodToNumber(values.payment_method),
      comment: values?.comment ?? "",
      shipping_price: `${Number(pvz?.price ?? 0) + additionalPrice}`,
      promo: promoData?.coupon?.code,
      address
    };

    createOrderHandler(input).then((res) => {
      if (res?.data?.createOrder?.status) {
        dispatch(setPromoError(null));
        dispatch({ type: SET_PROMO, payload: null });
        dispatch(setPromoIsUsed(false));
        dispatch(setPromoValue(""));
        processArray({
          array: products,
          itemFunc: (item) => {
            deleteProductFromCartHandler(item?.product_id || item?.productId, item?.option_id);
          },
          afterArrFunc: () => {
            window.location.href = res?.data?.createOrder?.payment_link
              ? res.data.createOrder.payment_link
              : `${URI}/order_accepted?order=${res?.data?.createOrder?.order_id}`
          }
        })
      } else {
        setOrderingError(res?.data?.createOrder?.message || 'Произошёл технический сбой, попробуйте позже!');
        setLoading(false);
      }

    });
  }, [deliveryInfo, pvz, products, orders.delivery, promoData?.coupon?.code]);

  const renderForm = useCallback(({ handleSubmit, values, submitting, pristine, errors }) => {
    if (submitting) {
      setLoading(true)
    }

    return (
      <form onSubmit={handleSubmit} className={styles.Ordering}>
        <div className={styles.Col}>
          <Contacts />
          <DeliveryType
            initialCourierPlace={values.courier_place}
            initialPickupPlace={values.pickup_place}
            setOrders={setOrders}
            orders={orders}
            courierPlace={values.courier_place}
            cdekRef={cdekRef}
            getDeliveryInfo={getDeliveryInfo}
            cities={cities}
            values={values}
            setPvz={setPvz}
          />
          <PaymentType initialPaymentMethod={values.payment_method} deliveryType={orders.delivery.type} />
          <Confirmations />
          <button
            type='submit'
            className={classnames(
              styles.Button,
              buttonStyles.Button,
              buttonStyles.Button_filled,
            )}
            disabled={
              userInfoLoading ||
              productsLoading ||
              submitting ||
              pristine ||
              !isEmptyObject(errors) ||
              !pvz?.type ||
              products.length === 0
            }
          >
            <span className={buttonStyles.Content}>
              Подтвердить заказ
            </span>
          </button>
        </div>
        <div className={styles.Col}>
          {!userCookie && <HaveAcc />}
          <Basket
            deliveryPrice={deliveryPrice}
            deliveryInfo={deliveryInfo}
          />
        </div>
      </form>
    )
  }, [cities, orders, pvz, deliveryPrice]);

  return (
    <Page
      title="Оформление заказа"
      classes={{
        content: styles.PageContent,
      }}
      popUpPage='ordering'
    >
      {orderingError && <span className={styles.OrderingError}>{orderingError}</span>}
      {loading ? (
        <div className={styles.Centered}>
          <Loader />
        </div>
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validateOrdering(orders.delivery)}
          render={renderForm}
        />
      )}
    </Page>
  );
};

export default Ordering;
