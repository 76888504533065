export const getShippingMethod = (type) => {
  if (type === 'door') {
    return 1;
  }

  if (type === 'office') {
    return 2;
  }

  if (type === 'pickup') {
    return 3;
  }

  return 3;
};
